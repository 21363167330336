<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model.trim="query.userName" clearable placeholder="请输入手机号"
					class="handle-input mr10" style="width: 180px"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column prop="userPhone" label="用户名" align="center"></el-table-column>
				<el-table-column prop="count" label="外呼次数" align="center"></el-table-column>
				<el-table-column prop="date" label="统计时间" align="center"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import { queryRequest } from '../api/index.js';
	export default {
		name: 'callsNumber',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					userName: ''
				},
				pageTotal: 0,
				tableData: []
			};
		},
		created() {},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},

			getData() {
				if (this.query.userName) {} else {}
				let data = {
					userName: this.query.userName
				};
				queryRequest(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error('请输手机号');
					}
				});
			},

			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}
</style>